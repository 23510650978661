:root {
  --light-grey: 246, 246, 246;
  --medium-grey: 211, 221, 225;
  --white: 255, 255, 255;
  --black: 0, 0, 0;
  --Primary-Bespoke-Blue: 0, 210, 255;
  --Primary-People-Purple: 70, 0, 235;
  --Gradient-Purple: 107, 51, 239;
  --Tones-Black: 0, 0, 0;
  --Tones-Black-90: 25, 25, 25;
  --Tones-Black-50: 128, 128, 128;
  --Tones-Grey: 230, 230, 230;
  --Tones-Platinum: 242, 242, 242;
  --Tones-White: 255, 255, 255;
  --Secondary-Orange: 255, 169, 102;
  --Secondary-Red: 238, 105, 113;
  --Secondary-Blue: 102, 228, 255;
  --Secondary-Purple: 144, 102, 243;
  --primary-color: 0, 210, 255;
  --secondary-color: 70, 0, 235;
}

@property --cardalpha {
  syntax: "<number>";
  inherits: false;
  initial-value: 0
}

@property --buttonangle {
  syntax: "<angle>";
  inherits: false;
  initial-value: 90deg
}

/* ATHauss font face declarations */
@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: normal;
  font-weight: 300;
  src: url(./app/fonts/Light.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: italic;
  font-weight: 300;
  src: url(./app/fonts/LightItalic.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: normal;
  font-weight: 400;
  src: url(./app/fonts/Regular.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: italic;
  font-weight: 400;
  src: url(./app/fonts/LightItalic.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: normal;
  font-weight: 600;
  src: url(./app/fonts/Semibold.woff2) format("woff2")
}

@font-face {
  font-display: swap;
  font-family: at-hauss;
  font-style: italic;
  font-weight: 600;
  src: url(./app/fonts/SemiboldItalic.woff2) format("woff2")
}
body {
  margin: 0;
  color:#000;
  font-family: 'at-hauss', Helvetica Neue, sans-serif;
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'at-hauss', Helvetica Neue, sans-serif;
}

#root {
  height: 101vh;
}

.card-background {
  background-image: url('./background-image.jpg');
  background-size: cover;
  background-position: center;
  color: white;
}

.card-white-background {
  background-image: url('./white-background-image.jpeg');
  background-size: cover;
  background-position: center;
  color: rgb(0, 0, 0);
}

.label-secondary {
  color: hsl(0, 0%, 1%);
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.label-secondary:not(:last-child) {
  margin-bottom: 0.5em;
}

.label-secondary.is-small {
  font-size: 0.75rem;
}

.label-secondary.is-medium {
  font-size: 1.25rem;
}

.label-secondary.is-large {
  font-size: 1.5rem;
}

.custombox {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #000000;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  height: 11.5rem;
  margin-bottom: 0.625rem;
}

a.custombox:hover,
a.custombox:focus {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px #128cd8;
}

a.custombox:active {
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.2), 0 0 0 1px #128cd8;
}

.page-title {
color: #000000;
}

.orange-line {
  height: 2px;
  background-color: #ffa966;
  margin: 8px 0;
}

.action-buttons {
  display: flex;
  align-items: center;
}