@charset "utf-8"

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700')

// Set your brand colors
$purple: #6B33EF
$pink: #128cd8
$brown: #757763
$beige-light: #d0d1cd
$beige-lighter: #eff0eb
$white: #ffffff
$blue: #66e4ff
$black: #000

// Update Bulma's global variables
$family-sans-serif: at-hauss
$grey-dark: $brown
$grey-light: $beige-light
$primary: $blue
$link: $pink
$widescreen-enabled: false
$fullhd-enabled: false

// Update some of Bulma's component variables
$body-background-color: $beige-lighter
$content-heading-color: $black
$label-color: $white
$link-text: $primary
$navbar-item-selected-background-l: $pink
$navbar-item-background-l-delta: $pink
$control-border-width: 2px
$input-border-color: $grey-light
$input-shadow: none
$navbar-h: 273
$navbar-s: 88%
$navbar-l: 25%

// Import only what you need from Bulma
@import "../node_modules/bulma/sass"
